<template>
  <div id="desc-short" class="big-m-b m-t-nav">
      <div class="wrap tt-up p-l p-r ta-j">
            <h2>
is a music exploration platform designed to attract and determine the best independent music and musicians in your local neighborhood, in your city, in your country and on planet Earth.
            </h2>
            <div class="d-f jc-fe">
                <router-link to="/about">Read more</router-link>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'DescShort',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#desc-short {
}
</style>