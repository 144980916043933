<template>
<div class="login-bar p-f tt-up p w-100 d-f jc-c ai-c bc-black bs-bb" v-show="showLoginBar && !getUser.loggedIn">
    <div class="d-f jc-sb ai-c w-100">
        <p class="invite grey w-100 ws-pre"><span class="blink">*</span>Invite only</p>
        <div class="d-f" @click="showLoginBar = false">
            <router-link :to="'/sign_in'" class="ws-pre">Sign In</router-link>
            <span class="ws-pre grey"> / </span>
            <router-link :to="'/sign_up'" class="ws-pre">Sign Up</router-link>
            <!-- <span class="ws-pre grey"> / </span>
            <a class="ws-pre" href="https://form.typeform.com/to/MrNJchf4" target="_blank">
                <span>Get invite</span>
            </a> -->
        </div>
        <div class="w-100 d-f jc-fe">
                <a class="d-f ai-c" @click="showLoginBar = false">
                    <IconBase w="16" h="16" icon-name="cancel"><IconCancel/></IconBase>
                </a>
            
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import IconBase from '@/assets/icons/IconBase.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

export default {
    name: 'LoginBar',
    components: {
        IconBase,
        IconCancel,
    },
    data () {
        return {
            showLoginBar: true
        }
    },
    computed: {
        ...mapGetters([
        'getUser',
        ]),
    },
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.login-bar {
    top: 45vh;
    @media #{$mobile} {
        .invite {
            // width: auto;
            // margin-right: 4px;
        }
    }
}
</style>
